import { FC, useState } from "react";
import { CircularProgress } from "@mui/material";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";

interface AuthenticatorMFAProps {
    generatedSecret: any;
    handleClose: Function;
}

const AuthenticatorMFA: FC<AuthenticatorMFAProps> = (props: AuthenticatorMFAProps) => {
    const [code, setCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const verifyCode = async () => {
        try {
            if (code) {
                setLoading(true);
                await HttpService.postFormData("/v1/mfa/verify", {
                    secret: props.generatedSecret?.secret,
                    code,
                    mfa_type: "AUTHENTICATOR"
                });
                setLoading(false);
                props.handleClose("AUTHENTICATOR", true);
            }
        } catch (error) {
            ToastService.userError("Invalid code");
            setLoading(false);
        }
    };

    return (
        <div className="container verify-dialog">
            <h3 className="text-dark m-auto pb-1 text-center">Setup Authenticator</h3>
            <h5 className="pt-2 pb-1">Configuring Google/Microsoft Authenticator or Authy</h5>
            <ol className="text-wrap">
                <li>Install Google Authenticator (iOS - Android) or Authy (iOS - Android)</li>
                <li>In the authenticator app, select '+' icon</li>
                <li>Select 'Scan a barcode (or QR code)' and use the phone's camera to scan this barcode</li>
            </ol>
            <h5 className="pt-1 pb-1">Scan QR Code</h5>
            <div className="text-center">
                <img src={props.generatedSecret?.qr_image} alt="QR Code" />
            </div>
            <h5 className="pt-2 pb-1">OR Enter below code into your app</h5>
            <p className="text-dark pt-2 text-break">{props?.generatedSecret?.secret}</p>
            <h5 className="pt-1 pb-1">Verify Code</h5>
            <input type="text" className="form-control mb-3" placeholder="Enter code shown in app" onChange={e => setCode(e.target.value)} />
            <div className="d-flex">
                <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" onClick={() => props.handleClose("AUTHENTICATOR")}>
                    Cancel
                </button>
                <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" disabled={!code || loading} onClick={verifyCode}>
                    Activate
                </button>
                {loading && <CircularProgress />}
            </div>
        </div>
    );
};

export default AuthenticatorMFA;
