import { FC } from "react";

interface MFAOptionsProps {
    handleClose: Function;
}

const MFAOptions: FC<MFAOptionsProps> = (props: MFAOptionsProps) => {
    return (
        <div className="container verify-dialog">
            <h3 className="text-dark m-auto pb-1 text-center pb-3">Select MFA Option</h3>
            <button className="btn btn-primary mb-3 px-3 text-white animated-text d-block w-100" onClick={() => props.handleClose("Authenticator")}>
                MFA via Authenticator App
            </button>
            <button className="btn btn-primary mb-3 px-3 text-white animated-text d-block w-100" onClick={() => props.handleClose("Phone")}>
                MFA via Phone
            </button>
        </div>
    );
};

export default MFAOptions;
