import { FC, useContext, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Box, CircularProgress } from "@mui/material";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import HttpService from "../../Services/HttpService";
import ToastService from "../../Common/ToastService";
import { AuthContext } from "../../Contexts/AuthContext";
import Error from "../../Common/Error";
import { generateOTP, maskPhoneNumber } from "../../Common/Helper";

interface PhoneMFAProps {
    generatedSecret: any;
    handleClose: Function;
}

export const inputStyles = {
    cursor: "pointer",
    border: 0,
    width: "50px",
    height: "50px",
    fontSize: "24px",
    borderRadius: 0,
    borderBottom: "3px solid #cbcbcb"
};

const PhoneMFA: FC<PhoneMFAProps> = (props: PhoneMFAProps) => {
    const authContext = useContext(AuthContext);
    const [code, setCode] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState();
    const generateCodeMutation = useMutation({
        mutationFn: () =>
            generateOTP({
                secret: props.generatedSecret?.secret,
                mfa_type: "PHONE_OTP"
            })
    });
    const isOTPGenerated = useRef<boolean>(false);

    useEffect(() => {
        if (phoneNumber && !isOTPGenerated.current) {
            generateCodeMutation.mutate();
            isOTPGenerated.current = true;
        }
    });

    const resendCode = async () => {
        generateCodeMutation.mutate();
    };

    const submitPhoneNumber = async () => {
        if (phoneNumber) {
            try {
                await HttpService.putFormData(`/v1/user/${authContext.user?.userId}`, {
                    phone_number: phoneNumber
                });
                const userData = JSON.parse(localStorage.getItem("userData") || "");
                userData.phoneNumber = phoneNumber;
                localStorage.setItem("userData", JSON.stringify(userData));
                authContext.setUserData(userData);
                ToastService.success("Phone number updated successfully.");
                generateCodeMutation.mutate();
            } catch (error: any) {
                ToastService.alertableError("Unable to update phone number", error);
            }
        }
    };

    const verifyCode = async () => {
        try {
            if (code) {
                setLoading(true);
                await HttpService.postFormData("/v1/mfa/verify", {
                    secret: props.generatedSecret?.secret,
                    code,
                    mfa_type: "PHONE_OTP"
                });
                setLoading(false);
                props.handleClose("PHONE_OTP", true);
            }
        } catch (error) {
            ToastService.userError("Invalid code");
            setLoading(false);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div className="container verify-dialog">
            {!authContext.user?.phoneNumber && (
                <>
                    <h3 className="text-dark m-auto pb-1 text-center">Update phone number</h3>
                    <h6 className="pt-3 pb-1">Please update phone number to proceed</h6>
                    <div>
                        <PhoneInput
                            className={"w-100 phone-input mb-2 mt-2 " + (phoneNumber && !isPossiblePhoneNumber(phoneNumber) ? "error" : "")}
                            placeholder="Enter phone"
                            style={{ ...inputStyles, fontSize: "16px" }}
                            defaultCountry="US"
                            // @ts-ignore
                            value={phoneNumber}
                            // @ts-ignore
                            onChange={setPhoneNumber}
                        />
                        {phoneNumber && !isPossiblePhoneNumber(phoneNumber) && <Error>Invalid phone number</Error>}
                    </div>
                    <div className="d-flex">
                        <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3 m-auto" disabled={!phoneNumber} onClick={submitPhoneNumber}>
                            Submit
                        </button>
                    </div>
                </>
            )}
            {authContext.user?.phoneNumber && (
                <>
                    <h3 className="text-dark m-auto pb-1 text-center">Validate code</h3>
                    <h6 className="pt-3 pb-2">
                        Please enter code sent to phone <Box sx={{ fontWeight: "bold", display: "inline" }}> {maskPhoneNumber(authContext.user?.phoneNumber)}</Box>
                    </h6>
                    <input type="text" className="form-control mb-3" placeholder="Enter code" onChange={e => setCode(e.target.value)} />
                    <div className="d-flex">
                        <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" onClick={() => props.handleClose()}>
                            Cancel
                        </button>
                        <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" disabled={!code || loading} onClick={verifyCode}>
                            Activate
                        </button>
                        <button className="btn btn-primary mb-3 px-3 text-white animated-text mr-3" onClick={resendCode}>
                            Resend Code
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default PhoneMFA;
