import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { Avatar, Box, Dialog, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { InlineWidget } from "react-calendly";
import { useLocation, useNavigate } from "react-router";
import { determineNavType, menuProps, formattedTimeSaved } from "../../Common/Helper";
import { AuthContext } from "../../Contexts/AuthContext";
import CreateEmovidButton from "../../Common/CreateEmovidButton";
import { Paths } from "../../Common/AppRoutes";
import { isWhitelistedUser } from "../../Common/GuardedRoute";
import NotificationsIconButton from "./NotificationsIconButton";
import { NavType } from "./NavBar";
import Popup from "../../Common/Popup";
import Login from "../Login/Login";
import CreatedVideosIcon from "../../Icons/CreatedVideosIcon";
import ContactSalesIcon from "../../Icons/ContactSalesIcon";
import { ITimeSavedData } from "../../Interfaces/ITimeSavedData";
import AnimatedTextBlurbs from "../../VideoLib/Common/AnimatedTextBlurbs";
import MenuToggle from "./MenuToggle";

export const NavLinksContainer = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
`;

interface NavLinksProps {
    showDashboardView?: boolean;
    hideCreateEmovidButton?: boolean;
    hideCreateEmovidButtonInMenu?: boolean;
    disableSignup?: boolean;
    timeSavedData?: ITimeSavedData;
    showTimeSaved?: boolean;
}

const NavLinks: FC<NavLinksProps> = (props: NavLinksProps) => {
    const THRESHOLD_TIME_SAVED = 0; // in minutes
    const labelForTime = (timeMinutes: number) => {
        return timeMinutes > 1440 ? "DAY : HRS" : "HRS : MIN";
    };
    const [timeSavedDurationLabels, setTimeSavedDurationLabels] = useState<string[]>([]);
    const [timeSavedLabels, setTimeSavedLabels] = useState<string[]>([]);
    const [timeSavedValues, setTimeSavedValues] = useState<string[]>([]);
    const authContext = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [navType, setNavType] = useState(NavType.default);
    const [isLoginOpen, setLoginOpen] = useState<boolean>(false);
    const [isScheduleDemoDialogOpen, setIsScheduleDemoDialogOpen] = useState<boolean>(false);
    const [disableBackdropDismiss, setDisableBackdropDismiss] = useState<boolean>(false);
    const [avatarMenuAnchorEl, setAvatarMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const avatarMenuOpen = Boolean(avatarMenuAnchorEl);
    const [hamburgerMenuAnchorEl, setHamburgerMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const hamburgerMenuOpen = Boolean(hamburgerMenuAnchorEl);

    useEffect(() => {
        let timeSavedDurationLabelsArr = [];
        let timeSavedLabelsArr = [];
        let timeSavedValuesArr = [];
        if ((props.timeSavedData?.total_time_saved || 0) > THRESHOLD_TIME_SAVED) {
            timeSavedDurationLabelsArr.push("All time");
            timeSavedLabelsArr.push(labelForTime(props.timeSavedData?.total_time_saved || 0));
            timeSavedValuesArr.push(formattedTimeSaved(props.timeSavedData?.total_time_saved));
        }
        if ((props.timeSavedData?.weekly_time_saved || 0) > THRESHOLD_TIME_SAVED) {
            timeSavedDurationLabelsArr.push("Last 7 days");
            timeSavedLabelsArr.push(labelForTime(props.timeSavedData?.weekly_time_saved || 0));
            timeSavedValuesArr.push(formattedTimeSaved(props.timeSavedData?.weekly_time_saved));
        }
        setTimeSavedDurationLabels(timeSavedDurationLabelsArr);
        setTimeSavedLabels(timeSavedLabelsArr);
        setTimeSavedValues(timeSavedValuesArr);
    }, [props.timeSavedData]);

    useEffect(() => {
        setNavType(determineNavType(location.pathname));
    }, [location, authContext.user]);

    const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAvatarMenuAnchorEl(event.currentTarget);
    };

    const handleHamburgerMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setHamburgerMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAvatarMenuAnchorEl(null);
        setHamburgerMenuAnchorEl(null);
    };

    const dashboardClicked = () => {
        handleClose();
        navigate(Paths.dashboard);
    };

    const myAccountClicked = () => {
        handleClose();
        navigate(Paths.myAccount);
    };

    const getSupportClicked = () => {
        handleClose();
        navigate(Paths.getSupport);
    };

    const sendFeedbackClicked = () => {
        handleClose();
        navigate(Paths.sendFeedback);
    };

    const usageGuideClicked = () => {
        handleClose();
        navigate(Paths.usageGuide);
    };

    const resourcesClicked = () => {
        handleClose();
        navigate(Paths.resources);
    };

    const logoutClicked = () => {
        handleClose();
        authContext.signOut();
        navigate(Paths.landing);
    };

    const createdVideosClicked = () => {
        handleClose();
        navigate(Paths.createdVideos);
    };

    const useCasesClicked = () => {
        handleClose();
        navigate(Paths.useCases);
    };

    const salesClicked = () => {
        handleClose();
        navigate(Paths.contactSales);
    };

    const supportClicked = () => {
        handleClose();
        navigate(Paths.getSupport);
    };

    const scheduleDemoClicked = () => {
        handleClose();
        setIsScheduleDemoDialogOpen(true);
    };

    const homeClicked = () => {
        handleClose();
        navigate(Paths.landing + "?show_home=true");
    };

    const signInClicked = () => {
        localStorage.setItem("waitlist_signup", "true");
        setLoginOpen(true);
    };

    const loginComplete = (reload = true) => {
        setLoginOpen(false);
        setDisableBackdropDismiss(false);
        localStorage.setItem("waitlist_signup", "true");
        if (sessionStorage.getItem("promo_code") && !isWhitelistedUser(authContext)) navigate(Paths.landing);
    };

    const showExtraLinks = (): boolean => {
        if (window.location.pathname.indexOf("/edit-video") >= 0 || window.location.pathname.indexOf("/record") >= 0 || window.location.pathname.indexOf("/preview") >= 0)
            return false;
        return !authContext.user;
    };

    const showNonDismissableLoginDialog = () => {
        setDisableBackdropDismiss(true);
        setLoginOpen(true);
    };

    return (
        <NavLinksContainer>
            <Fragment>
                {navType === NavType.default && (
                    <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", position: "relative" }}>
                        {props.timeSavedData && props.showTimeSaved && timeSavedValues.length > 0 && (
                            <table className="position-absolute" style={{ left: 0, transform: "translateX(-110%)", minWidth: "160px" }}>
                                <tbody>
                                    <tr>
                                        <td className="pt-0 pb-0 text-right text-small fw-bold">TIME SAVED</td>
                                        <td className="pt-0 pb-0 ps-2 pe-2 text-small fw-bold">
                                            {labelForTime(props.timeSavedData?.total_time_saved) === labelForTime(props.timeSavedData?.weekly_time_saved) && (
                                                <>{labelForTime(props.timeSavedData?.weekly_time_saved)}</>
                                            )}
                                            {labelForTime(props.timeSavedData?.total_time_saved) !== labelForTime(props.timeSavedData?.weekly_time_saved) && (
                                                <AnimatedTextBlurbs containerClasses="d-block" textElementClasses="text-right" items={timeSavedLabels} duration={10000} />
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="pt-1 pb-0">
                                            <AnimatedTextBlurbs containerClasses="d-block" textElementClasses="text-right" items={timeSavedDurationLabels} duration={10000} />
                                        </td>
                                        <td className="pt-1 pb-0 ps-2 pe-2">
                                            <AnimatedTextBlurbs containerClasses="d-block" textElementClasses="time-saving digital" items={timeSavedValues} duration={10000} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={useCasesClicked}>
                                Use Cases
                            </button>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={usageGuideClicked}>
                                Usage Guide
                            </button>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={salesClicked}>
                                Sales
                            </button>
                        )}
                        {showExtraLinks() && (
                            <button className="btn btn-link svg-shadow" onClick={supportClicked}>
                                Support
                            </button>
                        )}
                        {!authContext.user?.userId && (
                            <button className="btn btn-link svg-shadow" onClick={signInClicked}>
                                Sign In
                            </button>
                        )}
                        {authContext.user && (
                            <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                                {isWhitelistedUser(authContext) && !props.hideCreateEmovidButton && <CreateEmovidButton location="nav_bar" />}
                                {isWhitelistedUser(authContext) && <NotificationsIconButton />}
                                <Tooltip title={(authContext.user?.name || authContext.user?.email.split("@")[0]) + " (" + authContext.user?.email + ")"}>
                                    <IconButton className="p-0 ms-2 me-3 user-avatar" onClick={handleAvatarClick}>
                                        <Avatar
                                            sx={{ width: "2rem", height: "2rem" }}
                                            alt={authContext.user?.name || authContext.user?.email}
                                            src={authContext.user.profileImageUrl || "/static/images/avatar/3.jpg"}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Emovid Options">
                                    <>
                                        <MenuToggle isOpen={false} toggle={handleHamburgerMenuClick} />
                                    </>
                                </Tooltip>
                            </Box>
                        )}
                    </Box>
                )}
                <Menu
                    anchorEl={hamburgerMenuAnchorEl}
                    id="account-menu"
                    open={hamburgerMenuOpen}
                    onClose={handleClose}
                    slotProps={{ paper: menuProps }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    {isWhitelistedUser(authContext) && !props.hideCreateEmovidButtonInMenu && (
                        <CreateEmovidButton onSuccess={handleClose} isMenuButton={true} buttonText="Create New Video" location="nav_menu"></CreateEmovidButton>
                    )}
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={dashboardClicked}>
                            <ListItemIcon>
                                <DashboardIcon fontSize="small" />
                            </ListItemIcon>
                            My Inbox
                        </MenuItem>
                    )}
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={createdVideosClicked}>
                            <ListItemIcon>
                                <CreatedVideosIcon style={{ width: "1.2rem", height: "1.2rem" }} />
                            </ListItemIcon>
                            Created Videos
                        </MenuItem>
                    )}
                    {isWhitelistedUser(authContext) && <Divider className="ms-2 me-2" />}
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={resourcesClicked}>
                            <ListItemIcon>
                                <InfoRoundedIcon width="24px" />
                            </ListItemIcon>
                            Resources
                        </MenuItem>
                    )}
                    <MenuItem onClick={homeClicked}>
                        <ListItemIcon>
                            <HomeRoundedIcon width="24px" />
                        </ListItemIcon>
                        Home
                    </MenuItem>
                    <Divider className="ms-2 me-2" />
                    {authContext.user?.userId && (
                        <MenuItem onClick={getSupportClicked}>
                            <ListItemIcon>
                                <HelpRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Get Support
                        </MenuItem>
                    )}
                    {authContext.user?.userId && (
                        <MenuItem onClick={scheduleDemoClicked}>
                            <ListItemIcon>
                                <EventAvailableRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Schedule a Demo
                        </MenuItem>
                    )}
                    {authContext.user?.userId && (
                        <MenuItem onClick={sendFeedbackClicked}>
                            <ListItemIcon>
                                <ThumbUpAltRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            Send Feedback
                        </MenuItem>
                    )}
                    <MenuItem onClick={salesClicked}>
                        <ListItemIcon>
                            <ContactSalesIcon />
                        </ListItemIcon>
                        Contact Sales
                    </MenuItem>
                    {authContext.user?.userId && <Divider className="ms-2 me-2" />}
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={myAccountClicked}>
                            <ListItemIcon>
                                <ManageAccountsRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            My Account
                        </MenuItem>
                    )}
                    {authContext.user?.userId && (
                        <MenuItem onClick={logoutClicked}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    )}
                </Menu>
                <Menu
                    anchorEl={avatarMenuAnchorEl}
                    id="account-menu"
                    open={avatarMenuOpen}
                    onClose={handleClose}
                    slotProps={{ paper: menuProps }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                    {isWhitelistedUser(authContext) && (
                        <MenuItem onClick={myAccountClicked}>
                            <ListItemIcon>
                                <ManageAccountsRoundedIcon fontSize="small" />
                            </ListItemIcon>
                            My Account
                        </MenuItem>
                    )}
                    {isWhitelistedUser(authContext) && <Divider className="ms-2 me-2" />}
                    <MenuItem onClick={logoutClicked}>
                        <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                        </ListItemIcon>
                        Logout
                    </MenuItem>
                </Menu>
            </Fragment>
            <Popup isOpen={isLoginOpen} disableBackdropDismiss={disableBackdropDismiss} onClose={() => setLoginOpen(false)} className="login-popup">
                <Login
                    title={disableBackdropDismiss ? "Please login to continue" : ""}
                    disableCloseBtn={disableBackdropDismiss}
                    handleClose={loginComplete}
                    disableSignup={props.disableSignup !== undefined ? props.disableSignup : true}
                />
            </Popup>
            <Dialog
                open={isScheduleDemoDialogOpen}
                className="more-popup"
                maxWidth={false}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                onClose={() => setIsScheduleDemoDialogOpen(false)}
            >
                <IconButton className="close-icon text-white" aria-label="Close" onClick={() => setIsScheduleDemoDialogOpen(false)} style={{ right: "20px" }}>
                    <CloseIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <InlineWidget url="https://calendly.com/d/cpqp-dr8-fd9/guided-product-walk-through-with-co-founder" />
            </Dialog>
            <button
                id="session-expiry-hidden-login-btn"
                className="position-absolute"
                style={{ top: "-50000px", left: "-50000px" }}
                onClick={showNonDismissableLoginDialog}
            ></button>
        </NavLinksContainer>
    );
};

export default NavLinks;
