import { FC } from "react";

interface LogoProps {
    theme?: string;
    className?: string;
}

const Logo: FC<LogoProps> = (props: LogoProps) => {
    return (
        <img
            className={props.className || ""}
            src={props.theme === "dark" ? "/images/logo-footer.svg" : "/images/logo-nav.svg"}
            alt={"Emovid Logo"}
            style={{ maxWidth: "100px" }}
        />
    );
};

export default Logo;
